import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import i18n from './lang'
import 'lib-flexible'

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.component('vue-draggable-resizable', VueDraggableResizable)

new Vue({
  router,
  i18n,
  created(){
    i18n.locale = localStorage.getItem('lang')
  },
  render: h => h(App),
}).$mount('#app')
