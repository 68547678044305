export default {
  languageChange: {
      'homeTitle': '我是iTON智能游戏助手',
      'homeSubTitlle': '今天想聊些什么？',
      'homeChildTitle': '作为你的智能游戏助手，我能帮助你在游戏中更有效地解决问题、提高游戏技巧，快乐享受游戏。',
      'inputInner': '写下你遇到的问题，看看我能否帮到你？',
      'talk': '对话',
      'history': '历史',
      'setting': '设置',
      'help': '帮助',
      'childPageTitle': 'iTON智能游戏助手',
      'historyRecord': '历史记录',
      'deletePrompt': '删除后无法恢复，是否继续删除？',
      'cancel': '取消',
      'delete': '删除',
      'keySetting': '按键设置',
      'sendMessage': '发送消息：',
      'enterNewline': 'Enter发送，Ctrl+Enter换行',
      'newlineEnter': 'Enter换行，Ctrl+Enter发送',
      'appearanceAndLayout': '外观与布局',
      'darkTheme': '深色主题：',
      'themeLanguage': '主题语言',
      'languageToggle': '语言切换：',
      'chinese': '中文',
      'english': '英文',
      'about': '关于',
      'version': '版本：',
      'statistics': '统计：',
      'historyDataError': '无法获取历史对话，请稍后重试。',
      'prompt': '提示',
      'onlyValueError': '未获取唯一值，请刷新页面获取。',
      'nameError': '修改名称出错，请稍后重试。', 
      'deleteError': '删除出错，请稍后重试。',
      'newChat': '新聊天',
      'againSend': '再次发送',
      'netWork': '网络状态似乎不好，AI智能助手没能收到您的消息。',
      'detailError': '获取详情出错，请稍后重试。',
      'deleteAll': '全部删除',
      'deleteAllPrompt': '删除后将全部清空，是否继续删除？',
      'confirm': '确认'
    }
}