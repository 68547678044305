import Vue from 'vue'
import VueRouter from 'vue-router'
import chatRouter from './chat'

Vue.use(VueRouter)

  const routes = [
    chatRouter,
    {
        path:'/*',
        redirect: '/chat'
    }
  ]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
