export default{
    path: '/chat', 
    // component: () => import('@/views/chat'),
    component: resolve => require(['@/views/chat'], resolve),
    beforeEnter: (to, from, next) => {
        if (!to.query.lang) {
            next({ path: to.path, query: { lang: 'zh' } })
        } else {
            next();
        }
    },
    meta: {
        keepAlive: true
    }
}