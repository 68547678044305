export default {
  languageChange: {
      'homeTitle': 'Hello there, this is iTON Smart Game Assistant',
      'homeSubTitlle': "New day, new journey. Let's talk.",
      'homeChildTitle': 'As your intelligent gaming assistant, I can help you solve in-game challenges more effectively, improve your gaming skills, and enjoy the game to the fullest.',
      'inputInner': "Say your challenges here…",
      'talk': 'Talk',
      'history': 'History',
      'setting': 'Settings',
      'help': 'Help',
      'childPageTitle': 'iTON Smart Game Assistant',
      'historyRecord': 'History Records',
      'deletePrompt': 'Deletion cannot be undone. Do you wish to continue?',
      'cancel': 'Cancel',
      'delete': 'Delete',
      'keySetting': 'Key Settings',
      'sendMessage': 'Send message:',
      'enterNewline': 'Press Enter to send, Ctrl+Enter for a new line.',
      'newlineEnter': 'Press Enter to go to the next line, Ctrl+Enter to send.',
      'appearanceAndLayout': 'Appearance and Layout',
      'darkTheme': 'Dark theme:',
      'themeLanguage': 'The theme language', 
      'languageToggle': 'language Toggle:',
      'chinese': 'Chinese',
      'english': 'English',
      'about': 'About',
      'version': 'Version:',
      'statistics': 'Statistics:',
      'historyDataError': 'Unable to retrieve historical conversations, please try again later.',
      'prompt': 'Prompt',
      'onlyValueError': 'Unique value not obtained, please refresh the page to retrieve it.',
      'nameError': 'Error updating the name, please try again later.', 
      'deleteError': 'Error deleting, please try again later.',
      'newChat': 'New Chat',
      'againSend': 'Send again',
      'netWork': "It seems the network is unstable, and the AI assistant couldn't receive your message.",
      'detailError': 'Error retrieving details, please try again later.',
      'deleteAll': 'Delete All',
      'deleteAllPrompt': 'All data will be cleared after deletion. Do you want to continue?',
      'confirm': 'Confirm'
    }
}