import VueI18n from 'vue-i18n'
import Vue from 'vue'

import en from '@/lang/en'
import zh from '@/lang/zh'

// import ElementLocale from 'element-ui/lib/locale'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import enLocale from 'element-ui/lib/locale/lang/en'

Vue.use(VueI18n)

const navLang = navigator.language || navigator.userLanguage
let localLang = navLang || false
let lang = localLang || localRead('local') || 'enUS'
lang = lang === 'zh-CN' ? 'zh' : 'en'

const i18n = new VueI18n({
  // locale: localStorage.getItem('lang') || lang,
  locale: (localStorage.getItem('lang') !== null && localStorage.getItem('lang') !== undefined) ? localStorage.getItem('lang') : lang,
  messages: {
    en: {
      ...en,
      ...enLocale
    },
    zh: {
      ...zh,
      ...zhLocale
    } 
  }
})

export default i18n;
